(function($) {
    doc.on("change", ".part_ui_file input", function() {
        let elm = $(this);
        elm.closest(".part_ui_file").removeClass("state--invalid");

        if(elm[0].files[0].size>1048576*9) //9MB
        {
            elm.closest(".part_ui_file").addClass("state--invalid").find("label").text(elm.closest(".part_ui_file").data("lng-size"));
            elm.replaceWith(elm.val('').clone(true));
        } else {
            elm.closest(".part_ui_file").find("label").text($(this).val().replace(/.*(\/|\\)/, ''));
        }
    });
})(jQuery);