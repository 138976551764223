$.lui("comp",".comp_contact",function (selector) {

    let map_element = selector.find("[data-google-map]");
    if(map_element.length) {
        $.importScript(cdnjs.googlemap.replace("APIKEY", map_element.data("google-map")), function(){
            let zoom = 17,
                coords = map_element.data('coords').split(/[\s,]+/),
                color = map_element.data("marker");


            let map = new google.maps.Map(map_element[0], {
                zoom: zoom,
                center: {
                    lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                },
                mapTypeControl: false,
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#181818"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#1b1b1b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#2c2c2c"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8a8a8a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#373737"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#3c3c3c"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#4e4e4e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3d3d3d"
                            }
                        ]
                    }
                ]
            });

            let image = {
                path: 'M 67.742661,1.0984404e-6 A 67.730464,67.740094 0 0 0 0,67.733514 67.730464,67.740094 0 0 0 67.742661,135.46667 67.730464,67.740094 0 0 0 135.46667,67.733514 67.730464,67.740094 0 0 0 67.742661,1.0984404e-6 Z',
                fillColor: color,
                strokeOpacity: 0,
                strokeWeight: 0,
                fillOpacity: 1,
                scale: 0.1,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(32, 64)
            };

            let map_marker = new google.maps.Marker({
                position: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                map: map,
                icon: image
            });

            google.maps.event.addListenerOnce(map, 'idle', function(){
                setTimeout(function(){
                    selector.find("canvas").parent().addClass("marker");
                },150);
            });

            google.maps.event.addListener(map_marker, 'click', function(){
                window.open(map_element.data("marker-url"), '_blank');
            });
        });
    }
});