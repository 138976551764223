$.lui("comp",".comp_visual",function (selector) {
    let slider = selector.find("[data-lib-flickity]");
    if(slider.length){
        $.lib_flickity(function(){
            slider.flickity({
                groupCells: false,
                cellSelector: ".elm_item",
                cellAlign: "left",
                setGallerySize: true,
                autoPlay: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                prevNextButtons: true,
                watchCSS: false,
                on: {
                    ready: function () {
                        slider.addClass("flickity--flex");
                        slider.find(".flickity-prev-next-button").appendTo(selector.find("[data-lib-flickity-nav]"));
                    }
                }
            });

            doc.on("resize",debounce(function () {
                slider.removeClass("flickity--flex").flickity("resize").addClass("flickity--flex");
            },150));
        })
    }

    selector.on("click","[data-scroll]",function () {
        selector.next("section,div").anchor_anim(500);
    })
});