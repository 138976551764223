$.lui("comp",".comp_site",function (selector) {
    let nav = selector.find(".wrp_comp_nav");

    if(nav.length) {
        selector.on("click","[data-click=\"expand\"]",function () {
            if(win.width()<768) {
                let el = $(this);
                if(el.hasClass("state--active")) {
                    nav.stop().slideUp(function () {
                        el.removeClass("state--active");
                    })
                }
                else {
                    el.addClass("state--active");
                    nav.stop().slideDown();
                }
            }
        })
    }
});