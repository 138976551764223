$.lui("comp",".comp_carousel_references",function (selector) {
    selector.each(function () {
        let selector = $(this);
        let slider = selector.find("[data-lib-flickity]");
        if(slider.length){
            let slides = slider.children();
            $.lib_flickity(function(){
                slider.flickity({
                    groupCells: false,
                    cellAlign: "left",
                    setGallerySize: true,
                    autoPlay: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    prevNextButtons: true,
                    watchCSS: false,
                    on: {
                        ready: function () {
                            slider.addClass("flickity--flex");
                            slider.find(".flickity-prev-next-button").appendTo(selector.find("[data-lib-flickity-nav]"));

                            if(slides.length < 4){
                                selector.addClass("flickity--disabled");
                            }
                        }
                    }
                });

                win.on("resize",debounce(function () {
                    slider.removeClass("flickity--flex");
                    slider.flickity("resize");
                    slider.addClass("flickity--flex");
                },250));
            })
        }
    });
});
