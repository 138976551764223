let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "autosize": "https://cdn.jsdelivr.net/npm/autosize@4.0.2/dist/autosize.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "stickyfill": "https://cdn.jsdelivr.net/npm/stickyfilljs@2.1.0/dist/stickyfill.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0.12/dist/fancybox.umd.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0.12/dist/fancybox.css"
};
